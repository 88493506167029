import getCookie from "./GetCookie";

export const ProductName = (link: HTMLAnchorElement): string | null => {
  return link.dataset.product || "";
};

export const ProviderName = (link: HTMLAnchorElement): string | null => {
  return link.dataset.provider || "";
};

export const GetPlacement = (link: HTMLAnchorElement): string => {
  return link.dataset.placement || "";
}

export const GetPosition = (link: HTMLAnchorElement): number|null => {
  return link.dataset.position ? parseInt(link.dataset.position) : null;
}

export const StartingPath = (): string => {
  return ""; // todo get starting path
};

export const Referrer = (): string => {
  return document.referrer;
};

export const ActiveCampaignId = (): string => {
  // get acid from cookie

  return getCookie("acid") || "";
};

export const EmailProviderName = (): string | null => {
  switch (true) {
    case ActiveCampaignId() !== "":
      return "Active Campaign";
    default:
      return null;
  }
};

export const SiteId = (): number => {
  return 24; // todo make this dynamic
};

export const GaClientId = (): string => {
  return getCookie("_ga") || "";
};

export const GetSourceName = (link: string): string => {
  // get query params from link
  const query = getQueryParams(link);

  switch (true) {
    case link.includes("trackwise"):
    case link.includes("salestring"):
      return query.source || "";
    case link.includes("online.adservicemedia.dk") ||
      link.includes("aslinkhub.com"):
      return query.sub2 || "";
    case link.includes("a=") && link.includes("as="):
      return query.epi2 || "";
    case link.includes("addrevenue"):
      // Check for 'dasite' cookie
      return (
        getCookie("dasite") ||
        //@ts-ignore
        elberegner_feed?.settings?.source_name ||
        "unknown"
      );
    //return query.r || "";
    default:
      return "";
  }
};

// interface QueryParams {
//   [key: string]: string;
// }

function getQueryParams(link: string): Record<string, string | null> {
  const url = new URL(link);
  return Object.fromEntries(url.searchParams.entries());
}

export const GetTimeStamp = (): string => {
  // get iso date
  return new Date().toISOString();
};

export const UTMSource = (): string => {
  return getCookie("utms") || "";
};

export const UTMMedium = (): string => {
  return getCookie("utmm") || "";
};

export const UTMCampaign = (): string => {
  return getCookie("utmc") || "";
};

export const DawaId = (): string => {
  // if window has dawa_id params
  const url = new URL(window.location.href);
  const params = Object.fromEntries(url.searchParams.entries());
  if (params.dawa_id) {
    return params.dawa_id;
  } else {
    return "";
  }
};
