import NetworkFactory from "./networks/NetworkFactory";
import trackLink from "./TrackLink";
import {TrackingConfig} from "../types.ts";

export const UpdateLinks = (config: TrackingConfig, extraSource: string = "", clickParams: Record<string, any> = {}) => {
  document.querySelectorAll("a").forEach((link: HTMLAnchorElement) => {
    if (
      !isMailToLink(link) &&
      !isTelLink(link) &&
      isExternalLink(link) &&
      isNotJavascriptLink(link) &&
      isNotEmptyHrefTag(link)
    ) {
      UpdateLinksForNetwork(link, config, extraSource, clickParams);
    }
  });
};

export const UpdateSingleLink = (
  link: HTMLAnchorElement,
  config: TrackingConfig,
  clickParams: Record<string, any> = {}
  ) => {
  if (
      !isMailToLink(link) &&
      !isTelLink(link) &&
      isExternalLink(link) &&
      isNotJavascriptLink(link) &&
      isNotEmptyHrefTag(link)
  ) {
      UpdateLinksForNetwork(link, config, "", clickParams);
  }
};

export const UpdateLinksForNetwork = (
  link: HTMLAnchorElement,
  config: TrackingConfig,
  extraSource: string = "",
  clickParams: Record<string, any> = {}
) => {
  const network = NetworkFactory(link);
  link.href = network?.updateLink(link, config, extraSource) || link.href;
  if (network) {
    link.rel = "nofollow";
    link.onclick = async (e) => {
      e.preventDefault();
      // dataLayer event

      const provider = link.dataset.provider;
      const product = link.dataset.product;

      // @ts-ignore
      const dataLayer = window.dataLayer || [];

      dataLayer.push({
        event: "affiliateLinkClicked",
        link: link.href,
        provider: provider,
        product: product,
      });

      await trackLink(link, network, config, clickParams);
    };
  }
};

const isExternalLink = (link: HTMLAnchorElement) => {
  return link.hostname !== window.location.hostname;
};

const isNotJavascriptLink = (link: HTMLAnchorElement) => {
  return !link.href.includes("javascript");
};

const isMailToLink = (link: HTMLAnchorElement) => {
  return link.href.includes("mailto:");
};

const isTelLink = (link: HTMLAnchorElement) => {
  return link.href.includes("tel:");
};

const isNotEmptyHrefTag = (link: HTMLAnchorElement) => {
  return link.href !== "";
};
