import {TrackingConfig} from "../types.ts";

const ValidateSiteId = (config: TrackingConfig): boolean => {
    if (!config.siteId) {
        console.error('Site ID is required');
        return false;
    }
    return true
}

export default ValidateSiteId;
