import {TrackingConfig} from "../types.ts";
import TrackClick from "../tracking/clicks.ts";

const TrackLink = async (link: HTMLAnchorElement, network: any, config: TrackingConfig, clickParams: Record<string, any> = {}) => {
  TrackLinkEvent(link);

  const uuid = await TrackClick(link, config, network, clickParams);

  // If there is a callback provided, invoke it first
  if (config.clickCallback) {
    config.clickCallback(uuid, link, () => {
      // Delay redirect to provider by 1000ms after callback is done
      setTimeout(() => {
        redirectToProvider(link);
      }, 1000);
    });
  } else {
    // If no callback, immediately redirect to the provider
    redirectToProvider(link);
  }

};

export const redirectToProvider = (link: HTMLAnchorElement, open = true) => {
  if (!open) {
    window.location.href = link.href;
    RedirectedEvent();
  } else {
    const result = window.open(link.href, "_blank");
    if (!result) {
      window.location.href = link.href;
    } else {
      RedirectedEvent();
    }
  }
};

const TrackLinkEvent = (link: HTMLAnchorElement) => {
  const event = new CustomEvent("trackLinkClicked", {
    detail: {
      link: link,
    },
  });
  window.dispatchEvent(event);
};

export const RedirectedEvent = () => {
  const event = new CustomEvent("redirected");
  window.dispatchEvent(event);
};

export default TrackLink;
