import Network from "./Network";
import IsGoogleConversion from "../../helpers/IsGoogleConversion";
import IsBingConversion from "../../helpers/IsBingConversion";

class AdserviceNetwork extends Network {
  constructor() {
    super();
    this.sourceKey = "sub2";
    this.refererKey = "sub";
    this.guidKey = "sub6";
    this.network = "adservice";
  }

  /**
   * Set network specific params
   * @param link
   */
  public setNetworkParams(link: HTMLAnchorElement): object {
    let params = super.setNetworkParams(link);

    if (this.gclidCookie && IsGoogleConversion(this.daSiteCookie)) {
      params = {
        ...params,
        sub4: this.gclidCookie,
      };
    }

    if (this.msclkidCookie && IsBingConversion(this.daSiteCookie)) {
      params = {
        ...params,
        sub4: this.msclkidCookie,
      };
    }

    if (this.acIdCookie) {
      params = {
        ...params,
        sub5: this.acIdCookie,
      };
    }

    if (this.clientIdCookie) {
      params = {
        ...params,
        sub3: this.clientIdCookie,
      };
    }

    return params;
  }
}

export default AdserviceNetwork;
