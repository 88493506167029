import GetQueryParam from "../helpers/GetQueryParam.ts";
import setCookie from "../helpers/SetCookie.ts";
import DeleteCookie from "../helpers/DeleteCookie.ts";

const StoreCookies = () => {
    const msclkid = GetQueryParam("msclkid");
    const gclid = GetQueryParam("gclid");
    const fbclid = GetQueryParam("fbclid");
    const acid = GetQueryParam("acid");
    const ac_campaign_name = GetQueryParam("ac_campaign_name");

    // Check and set msclkid cookie
    if (msclkid) {
        setCookie("msclkid", msclkid, 30);
        DeleteCookie("gclid");
        DeleteCookie("fbclid");
        console.log("Stored msclkid as cookie:", msclkid);
    }

    // Check and set gclid cookie
    if (gclid) {
        setCookie("gclid", gclid, 30);
        DeleteCookie("msclkid");
        DeleteCookie("fbclid");
        console.log("Stored gclid as cookie:", gclid);
    }

    if (fbclid) {
        setCookie("fbclid", fbclid, 30);
        DeleteCookie("msclkid");
        DeleteCookie("gclid");
        console.log("Stored fbclid as cookie:", fbclid);
    }

    if (acid) {
        setCookie("acid", acid, 30);
        console.log("Stored acid as cookie:", acid);
    }

    if (ac_campaign_name) {
        setCookie("ac_campaign_name", ac_campaign_name, 30);
        console.log("Stored ac_campaign_name as cookie:", ac_campaign_name);
    }
}

export default StoreCookies;
