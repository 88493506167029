import APIClient from "../lib/api/client.ts";
import {GaClientId, Referrer, UTMCampaign, UTMMedium, UTMSource} from "../helpers/HelpersFunctions.ts";
import {TrackingConfig} from "../types.ts";
import ValidateSiteId from "../helpers/ValidateSiteId.ts";
import getPaidSource from "../helpers/GetPaidSource.ts";
import GetPaidSourceClickId from "../helpers/GetPaidSourceClickId.ts";

const TrackVisitor = async (config: TrackingConfig): Promise<string|null> => {
    // Track visitor
    if(!ValidateSiteId(config)) return null;

    try {
        const res = await APIClient(config).post('/visitor', {
            site_id: config?.siteId,
            uuid: config.visitorId,
            referer: Referrer(),
            page_path: window.location.pathname,
            user_agent: navigator.userAgent,
            ga_client_id: GaClientId(),
            paid_source: getPaidSource(),
            paid_source_click_id: GetPaidSourceClickId(),
            utm_source: UTMSource(),
            utm_medium: UTMMedium(),
            utm_campaign: UTMCampaign(),
        });

        // set cookie with visitor id
        document.cookie = `al_visitor_id=${res.data.uuid}; path=/; max-age=31536000; samesite=strict`;

        return res.data.uuid;
    } catch (error) {
        console.error("Failed to track visitor", error);
        return null;
    }

}

export default TrackVisitor;
