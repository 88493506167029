import {TrackingConfig} from "../types.ts";
import ValidateSiteId from "../helpers/ValidateSiteId.ts";
import APIClient from "../lib/api/client.ts";
import {
    GaClientId, GetPlacement, GetPosition,
    ProductName,
    ProviderName,
    Referrer,
    UTMCampaign,
    UTMMedium,
    UTMSource
} from "../helpers/HelpersFunctions.ts";
import GenerateGUID from "../helpers/GenerateGUID.ts";
import {NetworkInterface} from "../links/networks/NetworkInterface.ts";
import getPaidSource from "../helpers/GetPaidSource.ts";
import GetPaidSourceClickId from "../helpers/GetPaidSourceClickId.ts";
import GetEmailCampaign from "../helpers/GetEmailCampaign.ts";
import GetACID from "../helpers/GetACID.ts";

const TrackClick = async (link: HTMLAnchorElement, config: TrackingConfig, network: NetworkInterface, clickParams: Record<string, any> = {}): Promise<string> => {
    // Track click
    let guid = GenerateGUID();
    let placement = GetPlacement(link);
    let provider_name = ProviderName(link);
    let product_name = ProductName(link);
    let position = GetPosition(link);

    if (network.getGuidParam()) {
        const guidParam = network.getGuidParam();
        // get guid from link
        const params = new URLSearchParams(link.search);
        guid = params.get(guidParam) || guid;

        placement = params.get("placement") || placement;
        provider_name = params.get("provider") || provider_name;
        product_name = params.get("product") || product_name;
        position = params.has("position") ? parseInt(params.get("position") ?? "0") : position;
    }

    if(!ValidateSiteId(config)) return guid;

    try {
        const res = await APIClient(config).post('/click', {
            uuid: guid,
            site_id: config?.siteId,
            visitor_uuid: config.visitorId,
            referer: Referrer(),
            page_path: window.location.pathname,
            network: network.network,
            user_agent: navigator.userAgent,
            product_name: product_name,
            provider_name: provider_name,
            ga_client_id: GaClientId(),
            paid_source: getPaidSource(),
            paid_source_click_id: GetPaidSourceClickId(),
            placement: placement,
            position: position,
            email_campaign: GetEmailCampaign(),
            acid: GetACID(),
            source: config.source,
            utm_source: UTMSource(),
            utm_medium: UTMMedium(),
            utm_campaign: UTMCampaign(),
            ...clickParams
        });

        return res.data.uuid as string;
    } catch (error) {
        return guid;
    }
}

export default TrackClick;
