import getCookie from "./GetCookie.ts";

const GetACID = () => {
    if(document.cookie.includes("acid")) {
        return getCookie("acid");
    }

    return "";
}

export default GetACID;
