import GenerateGUID from "../../helpers/GenerateGUID";
import {TrackingConfig} from "../../types.ts";

interface Params {
  dawaId?: string;
  [key: string]: any;
}

class Network {
  daSiteCookie: string = "";
  gclidCookie: string = "";
  msclkidCookie: string = "";
  acIdCookie: string = "";
  clientIdCookie: string = "";
  referer: string = "";
  path: string = "";
  // @ts-ignore
  source: string = "unknown";
  sourceKey: string = "source";
  guidKey: string | null = null;
  refererKey: string | null = null;
  network: string = "unknown";

  constructor() {
    this.daSiteCookie = this.getCookie("dasite");
    this.gclidCookie = this.getCookie("gclid");
    this.msclkidCookie = this.getCookie("msclkid");
    this.acIdCookie = this.getCookie("acid");
    this.clientIdCookie = this.getCookie("_ga");
    this.referer = document.referrer;
    this.path = window.location.pathname;
  }

  /**
   * Get source
   * @returns {string}
   */
  public getSource(): string {
    return this.daSiteCookie || this.source;
  }

  /**
   * Get source key
   * @returns {string}
   */
  public getSourceKey(): string {
    return this.sourceKey;
  }

  public getRefererKey(): string {
    return this.refererKey as string;
  }

  public hasRefererKey(): boolean {
    return this.refererKey !== null;
  }

  public getRefererPath(): string {
    // remove protocol and hostname
    let path = this.referer.replace(window.location.origin, "");
    // remove query string
    if (path.includes("?")) {
      path = path.split("?")[0];
    }
    return path;
  }

  private hasAddress(): boolean {
    const url = new URL(window.location.href);
    const params = Object.fromEntries(url.searchParams.entries());
    // if &address= param exists in current url
    return params.hasOwnProperty("address");
  }

  private isTopElement(link: HTMLAnchorElement): boolean {
    const url = new URL(link.href);
    const params = Object.fromEntries(url.searchParams.entries());

    if (
      params.hasOwnProperty(this.sourceKey) &&
      params[this.sourceKey].includes("Top")
    ) {
      return true;
    } else {
      return false;
    }
  }

  private getCookie(cookieString: string): string {
    if (document.cookie.includes(cookieString)) {
      const match = document.cookie.match(
        new RegExp(`${cookieString}=([^;]*)`),
      );
      if (match && match[1]) {
        return match[1];
      }
    }
    return "";
  }

  public setNetworkParams(link: HTMLAnchorElement): object {
    return {};
  }

  protected getParams(link: HTMLAnchorElement): Params {
    const url = new URL(link.href);
    return Object.fromEntries(url.searchParams.entries()) as Params;
  }

  public hasReferrer(): boolean {
    return this.referer.includes(window.location.hostname);
  }

  public updateRefererParam(link: HTMLAnchorElement): object {
    if (!this.hasRefererKey()) return {};

    const params = this.getParams(link);
    let newParams = {};

    if (!params.hasOwnProperty(this.getRefererKey()) && this.hasReferrer()) {
      newParams = {
        [this.getRefererKey()]: this.getRefererPath(),
      };
    } else {
      newParams = {
        [this.getRefererKey()]: this.path,
      };
    }

    return newParams;
  }

  public setSourceParams(
    link: HTMLAnchorElement,
    extraSource: string = "",
  ): object {
    let newParams = {};

    if (this.isSourceBlacklisted(link)) {
      return {};
    }

    if (this.hasAddress()) {
      newParams = {
        [this.getSourceKey()]: this.isTopElement(link)
          ? this.getSource() + "AdresseTop"
          : extraSource
          ? this.getSource() + "Adresse" + extraSource
          : this.getSource() + "Adresse",
      };
    } else {
      newParams = {
        [this.getSourceKey()]: this.isTopElement(link)
          ? this.getSource() + "Top"
          : extraSource
          ? this.getSource() + extraSource
          : this.getSource(),
      };
    }

    return newParams;
  }

  public updateLink(link: HTMLAnchorElement, config: TrackingConfig, extraSource: string = ""): string {
    // get params from link
    // get the params and convert to object
    this.source = config.source; // important sets the source

    const params = this.getParams(link);

    // merge params
    let mergedParams = {
      ...params,
      ...this.updateRefererParam(link),
      ...this.setGuidParam(link),
      ...this.setSourceParams(link, extraSource),
      ...this.setNetworkParams(link),
    };

    mergedParams = this.updateDawaParams(mergedParams);
    mergedParams = this.replaceVariableParams(mergedParams);

    // update link
    link.search = new URLSearchParams(mergedParams).toString();

    // convert html entities in link.search to normal character
    link.search = decodeURIComponent(link.search);

    return link.href;
  }

  private setGuidParam(link: HTMLAnchorElement) {
    if (!this.guidKey) return {};

    const params = this.getParams(link);
    const guid = GenerateGUID(); // click guid

    return {
      ...params,
      [this.guidKey]: guid,
    };
  }

  public getGuidParam(): string {
    return this.guidKey || "";
  }

  public isSourceBlacklisted(link: HTMLAnchorElement): boolean {
    const params = this.getParams(link);

    // check if source is blacklisted
    const blacklistedSources = ["banner"];

    if (params.hasOwnProperty(this.getSourceKey())) {
      // if blacklisted source is part of the source param
      const source = params[
        this.getSourceKey() as keyof typeof params
      ] as string;

      // loop through blacklisted sources, and test if source param includes any of the blacklisted sources
      for (let i = 0; i < blacklistedSources.length; i++) {
        if (source.toLowerCase().includes(blacklistedSources[i])) {
          return true;
        }
      }
    }

    return false;
  }

  /**
   * Update dawa params
   * @param params
   * @private
   */
  private updateDawaParams(params: Params): Params {
    // if dawa id is in url, remove it

    const serializedState = localStorage.getItem("state")
      ? JSON.parse(localStorage.getItem("state") as string)
      : null;

    if (params.hasOwnProperty("dawaId") && this.getCookie("dawaid")) {
      return {
        ...params,
        dawaId: this.getCookie("dawaid"),
      };
    } else if (
      params.hasOwnProperty("dawaId") &&
      serializedState?.form?.values?.address?.address_id
    ) {
      return {
        ...params,
        dawaId: serializedState.form.values.address.address_id,
      };
    } else if (params.hasOwnProperty("dawaId") && !this.getCookie("dawaid")) {
      // params is an object
      // remove dawaId from params object, remember we are using typescript
      delete params.dawaId;
    }

    return params;
  }

  private replaceVariableParams(params: Params): Params {
    const trustedParams = [
        "adDawaId", // dawa id
        "adEmail", // email
        "adPhone", // phone
        "adName", // contact name
        "adKwh", // kwh
        "adCars", // numbers of cars
        "adMoving" // moving
    ];

    let transformedParams = params;
    const adParams = localStorage.getItem("adParams") ? JSON.parse(localStorage.getItem("adParams") as string) : null;

    // loop through trusted params
    for (let i = 0; i < trustedParams.length; i++) {
        // check if param exists in params object
        if (params.hasOwnProperty(trustedParams[i]) && adParams?.hasOwnProperty(trustedParams[i])) {
            // replace the param with the value from the form
            transformedParams[trustedParams[i]] = adParams[trustedParams[i]];
        } else if (params.hasOwnProperty(trustedParams[i]) && !adParams?.hasOwnProperty(trustedParams[i])) {
            delete transformedParams[trustedParams[i]];
        }
    }

    return transformedParams;
  }
}

export default Network;
