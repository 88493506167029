import getCookie from "./GetCookie.ts";

const GetPaidSourceClickId = () => {
    if(document.cookie.includes("gclid")) {
        return getCookie("gclid");
    }

    if(document.cookie.includes("msclkid")) {
        return getCookie("msclkid");
    }

    if(document.cookie.includes("fbclid")) {
        return getCookie("fbclid");
    }

    return "";
}

export default GetPaidSourceClickId;
