import axios, { AxiosRequestConfig } from 'axios';
import getCookie from "../../helpers/GetCookie.ts";
import {TrackingConfig} from "../../types.ts";

const APIClient = (config: TrackingConfig) => {
    const client = axios.create({
        baseURL: config.sandbox ? import.meta.env.VITE_DEV_API_URL : import.meta.env.VITE_API_URL,
        headers: {
            'Content-Type': 'application/json',
            'Accept': 'application/json',
            'X-Api-Key': config.apiKey
        },
    });

    // @ts-ignore
    client.interceptors.request.use((config: AxiosRequestConfig) => {
        if (getCookie('al_visitor_id')) {
            // If there is no headers object, initialize it first
            config.headers = config.headers || {};
            config.headers['X-Visitor-Id'] = getCookie('al_visitor_id');
        }
        return config; // Don't forget to return the modified config
    });

    return client;
};

export default APIClient;
