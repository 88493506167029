import Network from "./Network.ts";
import isGoogleConversion from "../../helpers/IsGoogleConversion.ts";
import IsBingConversion from "../../helpers/IsBingConversion.ts";

class AwinNetwork extends Network {
    constructor() {
        super();
        this.sourceKey = "clickref2";
        this.refererKey = "clickref3";
        this.guidKey = "clickref";
        this.network = "awin";
    }

    /**
     * Set network specific params
     * @param link
     */
    public setNetworkParams(link: HTMLAnchorElement): object {
        let params = super.setNetworkParams(link);

        if (this.gclidCookie && isGoogleConversion(this.daSiteCookie)) {
            params = {
                ...params,
                clickref4: this.gclidCookie,
            };
        } else if (this.msclkidCookie && IsBingConversion(this.daSiteCookie)) {
            params = {
                ...params,
                clickref4: this.msclkidCookie,
            };
        }

        if (this.acIdCookie) {
            params = {
                ...params,
                clickref5: this.acIdCookie,
            };
        }

        if (this.clientIdCookie) {
            params = {
                ...params,
                clickref6: this.clientIdCookie,
            };
        }

        return params;
    }
}

export default AwinNetwork;
