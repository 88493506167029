import Network from "./Network";
import IsBingConversion from "../../helpers/IsBingConversion";
import IsGoogleConversion from "../../helpers/IsGoogleConversion";

class AdtractionNetwork extends Network {
  constructor() {
    super();
    this.sourceKey = "epi2";
    this.refererKey = "epi";
    this.guidKey = "epi4";
    this.network = "adtraction";
  }

  /**
   * Set network specific params
   * @param link
   */
  public setNetworkParams(link: HTMLAnchorElement): object {
    let params = super.setNetworkParams(link);

    if (this.gclidCookie && IsGoogleConversion(this.daSiteCookie)) {
      params = {
        ...params,
        epi3: this.gclidCookie,
      };
    }

    if (this.msclkidCookie && IsBingConversion(this.daSiteCookie)) {
      params = {
        ...params,
        epi3: this.msclkidCookie,
      };
    }

    if (this.acIdCookie) {
      params = {
        ...params,
        epi5: this.acIdCookie,
      };
    }

    if (this.clientIdCookie) {
      params = {
        ...params,
        epi6: this.clientIdCookie,
      };
    }

    return params;
  }
}

export default AdtractionNetwork;
