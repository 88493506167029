import NetworkFactory from "./networks/NetworkFactory.ts";
import {TrackingConfig} from "../types.ts";

const UpdateSource = (link: HTMLAnchorElement, source: string, config: TrackingConfig) => {
    const network = NetworkFactory(link);

    if (network) {
        link.href = network?.updateLink(link, config, source);
    }
}

export default UpdateSource;
