// import * as Sentry from "@sentry/browser";
import {UpdateLinks, UpdateSingleLink} from "./links/UpdateLinks";
import { RedirectedEvent } from "./links/TrackLink";
import { TrackingConfig } from "./types.ts";
import TrackVisitor from "./tracking/visitors.ts";
import TrackClick from "./tracking/clicks.ts";
import UpdateSource from "./links/UpdateSource.ts";
import StoreCookies from "./tracking/storeCookies.ts";

// Sentry.init({
//     dsn: "https://3b65ec5ed0bc829e3e2e5272aa78877c@o4504127859916800.ingest.us.sentry.io/4508653201063936",
//     integrations: [
//         Sentry.browserTracingIntegration(),
//         Sentry.replayIntegration(),
//     ],
//     // Tracing
//     tracesSampleRate: 0.5, //  Capture 100% of the transactions
//     // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
//     tracePropagationTargets: ["localhost", /^https:\/\/tracking.adlab\.dk\/.*/],
//     // Session Replay
//     replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
//     replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
// });

// Define the tracker function that returns an object with your methods
const Tracker = (() => {

    // Initial default configuration
    let configuration: TrackingConfig = {
        siteId: null,
        source: "unknown",
        apiKey: ""
    };

    // Update links with the current configuration
    const updateLinks = (extraSource: string = "", clickParams?: Record<string, any>) => {
        UpdateLinks(configuration, extraSource, clickParams);
    }

    /**
     * Store cookies on the client side
     * This is useful for tracking visitors across multiple pages
     */
    StoreCookies();

    // Call redirectedEvent function
    const redirectedEvent = () => RedirectedEvent();

    // Update the tracker configuration
    const updateConfig = (config: TrackingConfig) => {
        configuration = { ...configuration, ...config }; // Merge new config with current config
    };

    const validateConfig = (config: TrackingConfig) => {
        if(!config.siteId) {
            throw new Error("siteId is required in the configuration");
        }

        if(!config.apiKey) {
            throw new Error("apiKey is required in the configuration");
        }
    }

    const track = {
        visitor: async (visitorId?: string) => {
            if(visitorId) {
                configuration.visitorId = visitorId;
            }

            try {
                validateConfig(configuration);

                await TrackVisitor(configuration);
            } catch (error: unknown) {
                // Ensure error is of type Error and handle accordingly
                if (error instanceof Error) {
                    console.error(error.message);
                } else {
                    console.error("An unknown error occurred:", error);
                }
            }
        },
        click: async (link: HTMLAnchorElement, network: any) => {
            try {
                validateConfig(configuration);

                await TrackClick(link, configuration, network);
            } catch (error: unknown) {
                // Ensure error is of type Error and handle accordingly
                if (error instanceof Error) {
                    console.error(error.message);
                } else {
                    console.error("An unknown error occurred:", error);
                }
            }
        }
    }

    /**
     * Gives the ability to update the source of a link
     * For example, if a link is generated dynamically and the source is not known at the time of generation
     * @param link
     * @param source
     */
    const updateSource = (link: HTMLAnchorElement, source: string) => {
        UpdateSource(link, source, configuration);
    }

    const updateSingleLink = (link: HTMLAnchorElement, clickParams?: Record<string, any>) => {
        UpdateSingleLink(link, configuration, clickParams);
    }

    return {
        updateSource,
        updateConfig,
        updateLinks,
        updateSingleLink,
        track,
        redirectedEvent // Call RedirectedEvent externally
    };
})();

// Ensure window.adlab exists or initialize it
window.adlab = window.adlab || {};

// Attach Tracker methods directly to window.adlab
Object.assign(window.adlab, Tracker);
