import getCookie from "./GetCookie.ts";

const GetEmailCampaign = () => {
    if(document.cookie.includes("ac_campaign_name")) {
        return getCookie("ac_campaign_name");
    }

    return "";
}

export default GetEmailCampaign;
