import Network from "./Network";
import isGoogleConversion from "../../helpers/IsGoogleConversion";
import IsBingConversion from "../../helpers/IsBingConversion";

class TrackWiseNetwork extends Network {
  constructor() {
    super();
    this.sourceKey = "source";
    this.refererKey = "aff_sub";
    this.guidKey = "aff_sub2";
    this.network = "trackwise";
  }

  /**
   * Set network specific params
   * @param link
   */
  public setNetworkParams(link: HTMLAnchorElement): object {
    let params = super.setNetworkParams(link);

    if (this.gclidCookie && isGoogleConversion(this.daSiteCookie)) {
      params = {
        ...params,
        aff_sub4: this.gclidCookie,
      };
    } else if (this.msclkidCookie && IsBingConversion(this.daSiteCookie)) {
      params = {
        ...params,
        aff_sub4: this.msclkidCookie,
      };
    }

    if (this.acIdCookie) {
      params = {
        ...params,
        aff_sub5: this.acIdCookie,
      };
    }

    if (this.clientIdCookie) {
      params = {
        ...params,
        aff_sub3: this.clientIdCookie,
      };
    }

    return params;
  }
}

export default TrackWiseNetwork;
