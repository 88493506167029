import Network from "./Network";

class PartnerAdsNetwork extends Network {
  constructor() {
    super();
    this.sourceKey = "uid2";
    this.guidKey = "uid";
    this.network = "partnerads";
    //this.refererKey = "r";
  }
}

export default PartnerAdsNetwork;
