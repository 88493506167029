import Network from "./Network";

class AddrevenueNetwork extends Network {
  constructor() {
    super();
    this.guidKey = "r";
    //this.refererKey = "r";
    this.network = "adrevenue";
  }
}

export default AddrevenueNetwork;
